import { motion } from "framer-motion";
import { useLoaderData } from "react-router-dom";
import { useState, useEffect } from "react";
import ThreeDSculptures from "../Components/3Dsculptures/3Dsculptures";
import "./home.css";
import Showreel from "../Components/showreel/showreel";
import ConceptArtist from "../Components/conceptArtist/conceptArtist";
import Animations from "../Components/animations/animations";

import { MdArrowBack, MdArrowForward } from "react-icons/md";
import AboutMe from "../Components/aboutMe/aboutMe";

const Home = () => {
  const { images, showreel, animation, _3Dsculpting } = useLoaderData();
  const [selectedTab, setSelectedTab] = useState("CONCEPT ART");
  const [currentIndex, setCurrentIndex] = useState(0);

  const tabs = ["CONCEPT ART", "ANIMATIONS"];

  useEffect(() => {
    setSelectedTab(tabs[currentIndex]);
  }, [currentIndex]);

  const handleTabChange = (tab) => {
    const index = tabs.indexOf(tab);
    setCurrentIndex(index);
  };

  const handleNextTab = () => {
    setCurrentIndex((prev) => (prev + 1) % tabs.length);
  };

  const handlePrevTab = () => {
    setCurrentIndex((prev) => (prev - 1 + tabs.length) % tabs.length);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="overflow-hidden" id="main">
        {/* Showreel */}
        <Showreel props={showreel} />
        {/* Showreel */}

        {/* About Me */}
        <AboutMe />
        {/* About Me */}
        <br />

        {/* tabs  */}
        <div className="w-screen  md:block mx-2">
          <nav className="ml-[-8px] flex w-full mt-3 gap-16 text-white text-center cursor-pointer">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab md:w-1/4 md:text-base text-inherit text-[14px] ${
                  selectedTab === tab ? "selected font-semibold" : ""
                }`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </div>
            ))}
          </nav>
        </div>
        {/* tabs  */}

        {/* Main Body */}
        <div className="md:main mx-2 py-3 border-2">
          {/* <div className="md:hidden text-center border-b-2 h-10 text-white flex justify-between items-center mx-1 ">
            <button
              onClick={handlePrevTab}
              className="p-1 h-9 w-9 border-2 rounded-md active:bg-gray-500 flex justify-center items-center mb-3"
            >
              <MdArrowBack className="text-[20px]" />
            </button>
            <h2>{selectedTab}</h2>
            <button
              onClick={handleNextTab}
              className="p-1 border-2 h-9 w-9 rounded-md active:bg-gray-500 flex justify-center items-center mb-3"
            >
              <MdArrowForward className="text-lg" />
            </button>
          </div> */}
          {selectedTab === "CONCEPT ART" && <ConceptArtist props={images} />}
          {selectedTab === "ANIMATIONS" && <Animations props={animation} />}
        </div>
      </div>
      {/* Main Body */}
    </motion.div>
  );
};

export default Home;
