import { MdEmail } from "react-icons/md";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
const Footer = () => {
 
  function scrollIntoview(){
    const main = document.getElementById('main')
    main.scrollIntoView();
  }

   return(
    <footer
    style={{ backgroundColor: "" }}
    className="mt-10 bg-gradient-to-l from-blue-950 to-black h-auto"
  >
   
  
    <div>
    <div className="pt-4">
      <h1 className=" text-white text-center md:text-2xl">CONTACT ME</h1>

      <div className="grid md:flex justify-center md:gap-6 border-b-2 border-b-slate-600 mx-10 py-3">
        {/* Email */}
        <div className="mb-3 flex justify-center items-center">
          <a href="mailto:sbnilsson@gmail.com">
            <div className="Cbox flex items-center bgCon duration-300 w-[200px] border-gray-500 rounded-md border hover:border-2 hover:border-white hover:scale-105 tracking-wide">
              <MdEmail className="text-[25px] mx-2 text-white" />
              <h1 className="py-2 md:text-xl text-white text-bold">Email</h1>
            </div>
          </a>
        </div>

        {/* LinkedIn */}
        <div className="mb-3 flex justify-center items-center">
          <a href="https://www.linkedin.com/in/sebastian-nilsson-b27a3a248?utm_source=share&utm_campaign=share_via&utm_content=profile&utm">
            <div className=" flex items-center bgCon duration-300 w-[200px]  border-gray-500 rounded-md border hover:border-2 hover:border-white hover:scale-105 tracking-wide">
              <FaLinkedin className="text-[25px] mx-2 text-white" />
              <h1 className="py-2 md:text-xl text-white text-bold">
                LinkedIn
              </h1>
            </div>
          </a>
        </div>
        {/* Instagram */}
        <div className="mb-3 flex justify-center items-center">
          <a href="https://www.instagram.com/acewantstodraw/">
            <div className="Cbox flex items-center bgCon duration-300 w-[200px] border-gray-500 rounded-md border hover:border-2 hover:border-white hover:scale-105 tracking-wide">
              <FaInstagram className="text-[25px] mx-2 text-white" />
              <h1 className="py-2 md:text-xl text-white text-bold">
                Instagram
              </h1>
            </div>
          </a>
        </div>
      </div>
      <h3 className="text-white text-center hover:underline cursor-pointer hover:text-blue-400 underline" onClick={scrollIntoview}>Go to top</h3>
    </div>
    

    {/* Credits */}
    {/* <div className="text-white grid justify-end md:text-md mx-4 mt-5 text-sm">
      <h1>Credit to the artist: Sebastian Nilsson</h1>
      <h1>Website Created by: Jeremiah Okoye </h1>
      <div className="flex gap-2">
        <h1>Contact:</h1>
        <div className="mb-3 items-center underline hover:text-blue-400">
          <a href="mailto:jeremiahokoye29@gmail.com">
            <h1>Jeremiahokoye29@gmail.com</h1>
          </a>
        </div>
      </div>
    </div> */}
  </div>
  </footer>
   )
};

export default Footer;
