import axios from "axios";

export async function CloudinaryImages() {
  try {
    const showreel = await axios.get(
      "https://ace-portfolio-backend.vercel.app/cloudinary/images/Portfolio_Showreel"
    );
    const ConceptArtist = await axios.get(
      "https://ace-portfolio-backend.vercel.app/cloudinary/folders/conceptart"
    );
    const Animations = await axios.get(
      "https://ace-portfolio-backend.vercel.app/cloudinary/folders/2danimation"
    );
    // const _3DScuptures = await axios.get(
    //   "https://ace-portfolio-backend.vercel.app/cloudinary/folders/3dsculpting"
    // );
    return {
      images: ConceptArtist.data,
      showreel: showreel.data,
      animation: Animations.data,
      // _3Dsculpting: _3DScuptures.data,
    };
  } catch (error) {
    // console.error("Error fetching Cloudinary images:", error);
    // throw error;
  }
}
